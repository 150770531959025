export default [
  {
    url: '/',
    name: 'Dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    role: 'operator',
    i18n: 'dashboard'
  },
  {
    url: '/orders',
    name: 'Orders',
    i18n: 'orders',
    tag: '55',
    tagColor: 'warning',
    slug: 'orders',
    icon: 'ShoppingCartIcon',
    role: 'stock_operator'
  },
  // {
  //   url: '/bulk-orders',
  //   name: 'Bulk Orders',
  //   i18n: 'bulkOrders',
  //   tag: '12',
  //   tagColor: 'danger',
  //   slug: 'bulk-orders',
  //   icon: 'ShoppingCartIcon',
  //   role: 'stock_operator'
  // },
  {
    header: 'Store',
    icon: 'PackageIcon',
    i18n: 'store',
    items: [
      {
        url: '/banners',
        name: 'Banners',
        i18n: 'banners',
        slug: '/banners',
        icon: 'ImageIcon',
        role: 'stock_operator'
      },
      {
        url: '/categories',
        name: 'Categories',
        i18n: 'categories',
        slug: 'email',
        icon: 'GridIcon',
        role: 'stock_operator'
      },
      {
        url: '/brands',
        name: 'Brands',
        i18n: 'brands',
        slug: 'brands',
        icon: 'AwardIcon',
        role: 'stock_operator'
      },
      {
        url: '/products',
        name: 'Products',
        i18n: 'products',
        slug: 'products',
        icon: 'BoxIcon',
        role: 'stock_operator'
      },
      {
        url: '/combo-products',
        name: 'Combo Products',
        i18n: 'comboProducts',
        slug: 'todo',
        icon: 'ArchiveIcon',
        role: 'stock_operator'
      },
      {
        url: '/campaigns',
        name: 'Campaigns',
        i18n: 'campaigns',
        slug: 'campaigns',
        icon: 'TagIcon',
        role: 'stock_operator'
      },
      {
        url: '/promos',
        name: 'PromoCodes',
        i18n: 'promoCodes',
        slug: 'promos',
        icon: 'StarIcon',
        role: 'operator'
      },
      {
        url: '/soldout',
        name: 'Out Of Stock',
        i18n: 'outOfStock',
        tag: 12,
        tagColor: 'danger',
        slug: 'soldout',
        icon: 'ShoppingCartIcon',
        role: 'stock_operator'
        // submenu: [
        //   {
        //     url: '/soldout',
        //     name: 'Products',
        //     i18n: 'Products',
        //     tag: 7,
        //     tagColor: 'danger',
        //     slug: 'soldout'
        //   }
        // ]
      },
      {
        url: '/supplier-orders',
        name: 'Supplier Orders',
        i18n: 'supplierOrders',
        slug: 'page2',
        icon: 'TerminalIcon',
        role: 'stock_operator'
      },
      {
        url: '/video-banners',
        name: 'Entertainment',
        i18n: 'entertainment',
        icon: 'YoutubeIcon',
        role: 'operator'
      }
    ]
  },
  {
    header: 'Staff',
    icon: 'PackageIcon',
    i18n: 'staff',
    items: [
      {
        url: '/users',
        name: 'Users',
        i18n: 'users',
        slug: 'users',
        icon: 'UsersIcon',
        role: 'stock_operator'
      },
      {
        url: '/operators',
        name: 'Operators',
        i18n: 'operators',
        slug: 'operators',
        icon: 'HeadphonesIcon',
        role: 'superadmin'
      },
      {
        url: '/couriers',
        name: 'Couriers',
        i18n: 'couriers',
        slug: 'couriers',
        icon: 'TruckIcon',
        role: 'stock_operator'
        // isDisabled: true
      },
      {
        url: '/suppliers',
        name: 'Suppliers',
        i18n: 'suppliers',
        slug: 'suppliers',
        icon: 'CheckSquareIcon',
        role: 'stock_operator'
        // i18n: 'Todo'
      },
      {
        url: '/wholesalers',
        name: 'Wholesalers',
        i18n: 'wholesalers',
        slug: 'wholesalers',
        icon: 'MapPinIcon',
        tagColor: 'success',
        role: 'stock_operator'
        // isDisabled: true
      }
    ]
  },
  
  {
    header: 'Settings',
    icon: 'MapIcon',
    i18n: 'settings',
    items: [
      {
        url: '/regions',
        name: 'Regions',
        i18n: 'regions',
        slug: 'regions',
        icon: 'MapIcon',
        role: 'stock_operator'
      },
      {
        url: '/deliveries',
        name: 'Delivery',
        i18n: 'delivery',
        slug: 'delivery',
        icon: 'TruckIcon',
        role: 'stock_operator'
      },
      {
        url: '/faq',
        name: 'FAQ\'s',
        i18n: 'faqs',
        slug: 'faq',
        icon: 'HelpCircleIcon',
        role: 'operator'
      },
      {
        url: '/sms-senders',
        name: 'SMS senders',
        i18n: 'smsSenders',
        slug: 'sms-senders',
        icon: 'MessageCircleIcon',
        role: 'admin'
      }
    ]
  },
  {
    header: 'Contacts',
    icon: 'PackageIcon',
    i18n: 'apps',
    role: 'admin',
    items: [
      {
        url: '/contact',
        name: 'Contact us',
        i18n: 'contactUs',
        slug: 'contact',
        icon: 'PhoneIcon',
        role: 'admin'
      },
      {
        url: '/about',
        name: 'About us',
        i18n: 'aboutUs',
        slug: 'email',
        icon: 'MailIcon',
        role: 'admin'
      },
      {
        url: '/terms',
        name: 'Terms & Conditions',
        i18n: 'termsConditions',
        slug: 'chat',
        icon: 'MessageSquareIcon',
        role: 'admin'
      },
      {
        url: '/delivery-info',
        name: 'Delivery Policies',
        i18n: 'deliveryPolicies',
        slug: 'delivery-info',
        icon: 'CheckSquareIcon',
        role: 'admin'
      },
      {
        url: '/payment-info',
        name: 'Payment Info',
        i18n: 'paymentInfo',
        slug: 'payment-info',
        icon: 'CreditCardIcon',
        role: 'admin'
      },
      {
        url: '/order-info',
        name: 'Order Info',
        i18n: 'orderInfo',
        slug: 'order-info',
        icon: 'PackageIcon',
        role: 'admin'
      }
    ]
  }
]
