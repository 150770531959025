<template>
  <div
    v-if="$acl.check(role)"
    class="vs-sidebar--item"
    :class="[
      {'vs-sidebar-item-active'            : activeLink},
      {'disabled-item pointer-events-none' : disabled}
    ]" >

      <router-link
        tabindex="-1"
        v-if="to"
        exact
        :class="[{'router-link-active': activeLink}]"
        :to="to"
        :target="target" >
          <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
          <feather-icon v-else :class="{'w-3 h-3': iconSmall}" :icon="icon" />
          <slot />
      </router-link>

      <a v-else :target="target" :href="href" tabindex="-1">
        <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
        <feather-icon v-else :class="{'w-3 h-3': iconSmall}" :icon="icon" />
        <slot />
      </a>
  </div>
</template>

<script>
export default {
  name: 'v-nav-menu-item',
  props: {
    icon        : { type: String,                 default: ''               },
    iconSmall   : { type: Boolean,                default: false            },
    iconPack    : { type: String,                 default: 'material-icons' },
    href        : { type: [String, null],         default: '#'              },
    to          : { type: [String, Object, null], default: null             },
    slug        : { type: String,                 default: null             },
    index       : { type: [String, Number],       default: null             },
    featherIcon : { type: Boolean,                default: true             },
    target      : { type: String,                 default: '_self'          },
    isDisabled  : { type: Boolean,                default: false            },
    role        : { type: String,                 default: '' }
  },
  data () {
    return {
      roles: ['stock_operator', 'operator', 'admin', 'superadmin']
    }
  },
  computed: {
    activeLink () {
      return !!((this.to === this.$route.path
        || (this.to !== '/' && this.$route.path.includes(this.to))
        || this.$route.meta.parent === this.slug)
        && this.to)
    },
    disabled (props) {
      const userRole = this.$store.getters['auth/role']

      if (props.isDisabled) return true
      else if (userRole === 'superadmin') return false
      else if (userRole === 'admin' && props.role !== 'superadmin') return false
      else if (userRole === 'operator' && (props.role !== 'superadmin' && props.role !== 'admin')) return false
      else if (userRole === 'stock_operator' && props.role === 'stock_operator') return false
      else return true
    }
  }
}

</script>

